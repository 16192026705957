import React from "react";
import "./App.css";
import horse from "./assets/leili.jpg"; 


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Binnenkort komt hier live:</h1>
        <img src={horse} alt="Paard" className="App-logo" />
        <h1 className="big-text">DWARS DOOR HET LEVEN</h1>
      </header>
    </div>
  );
}

export default App;
